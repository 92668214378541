import React from 'react'
import styled from 'styled-components'
import { CardActions } from '@material-ui/core'
import { TransparentCard } from './TransparentCard'

interface Props {
	onClick?: () => void
	icon: React.ReactNode
	children: React.ReactNode
	style?: React.CSSProperties
}

const IconCard = ({ onClick, icon, children, style }: Props) => (
	<Outer style={style} clickable={!!onClick} onClick={onClick}>
    {icon}
		<Actions>{children}</Actions>
	</Outer>
)

export default IconCard

const Actions = styled(CardActions)`
	&& {
		justify-content: center;
	}
`

const Outer = styled(TransparentCard).attrs({ variant: 'light' })<{ clickable?: boolean }>`
	margin: 24px;
	min-width: 230px;
	justify-content: center;
	align-items: center;
	text-align: center;
	${({ clickable }) =>
		clickable
			? `transition: box-shadow .3s ease-out;
  cursor: pointer;
  &:hover {
    opacity: 0.95;
    box-shadow: 0 4px 10px black;
  }`
			: ''}
`
