import React from 'react'
import MainWrapper from "../../components/ui/MainWrapper";
import Layout from '../../components/layout/layout'
import SEO from '../../components/seo/seo'
import ChooseAccountType from '../../components/signup/chooseAccountType'

const IndexPage = () => (
  <MainWrapper>
    <Layout backButton="/">
      <SEO title="Signup" keywords={['signup', 'new account']} canonicalUrl="https://app.fairmarkethome.com/signup"/>
      <ChooseAccountType/>
    </Layout>
  </MainWrapper>
)

export default IndexPage
