import React from 'react'
import { Button, withStyles, Grid, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import { Location } from '@reach/router';

import IconCard from '../ui/IconCard'

import contractorImg from '../../images/worker.svg'
import homeownerImg from '../../images/home-account.svg'

const styles = {
	grid: {
		width: '100vw',
		minHeight: '90vh',
	},
}

const ChooseAccountType = ({ classes }) => (
	<Location>
		{({ location }) => (
			<Grid className={classes.grid} alignItems="center" justify="center" container direction="column">
				<Typography variant="h5" style={{ color: 'white' }}>I Will Use This Account:</Typography>
				<Cards>
					<Button onClick={() => navigate(`/signup/contractor${location.hash}`)} style={{ minWidth: 229, margin: '34px 24px' }} size="medium" className={classes.button}>
						{"To Perform Services"}
					</Button>
					<Button onClick={() => navigate('/signup/homeowner')} style={{ minWidth: 229, margin: '34px 24px' }} size="medium" className={classes.button}>
						{"For Home Management"}
					</Button>
				</Cards>
			</Grid>
		)}
	</Location>
)

const IconImg = styled.img`
	width: 64px;
	height: 64px;
	object-fit: contain;
`

const Cards = styled.div`
	flex-direction: row;
	align-items: center;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
`

ChooseAccountType.propTypes = {
	classes: PropTypes.objectOf(PropTypes.string).isRequired,
}

export default withStyles(styles)(ChooseAccountType)
