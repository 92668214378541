import React from 'react'
import styled from 'styled-components'
import { MuiThemeProvider, createMuiTheme, CssBaseline } from '@material-ui/core'

import mui_theme from '../../../mui-theme'

const theme = createMuiTheme(mui_theme)

const MainWrapper = ({ children }: { children: React.ReactNode }) => (
	<MuiThemeProvider theme={theme}>
		<>
			<CssBaseline />
			<AppContainer>{children}</AppContainer>
		</>
	</MuiThemeProvider>
)

export default MainWrapper

const AppContainer = styled.div`
	display: flex;
	color: white;
	min-height: 100vh;
	align-items: stretch;
	background: #152747;
	font-family: "Open Sans", "Helvetica", "Arial", sans-serif;
`
