import styled from 'styled-components'
import { Card } from '@material-ui/core'

type TransparentCardVariants = 'light' | 'dark'

export const TransparentCard = styled(Card)<{ variant?: TransparentCardVariants }>`
	background: white;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
	border-radius: 2px;
	padding: 22px;
`
