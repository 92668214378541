const optionDrawerFixedWidth = '330px'

const SemiBold = {
	fontWeight: 600,
	fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif'
};

module.exports = {
	typography: {
		useNextVariants: true,
		fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
		body1: {
			fontSize: '14px'
		},
		h6: { ...SemiBold },
		h5: { ...SemiBold },
		h4: { ...SemiBold }
	},
	optionDrawerWidth: {
		width: '90%',
		'@media (min-width:400px)': {
			width: optionDrawerFixedWidth,
		},
	},
	houseCanvasWidth: {
		width: '100vw',
		'@media (min-width:850px)': {
			width: `calc(100% - ${optionDrawerFixedWidth})`,
		},
	},
	root: {
		display: 'flex',
	},
	palette: {
		primary: {
			main: '#E2B985',
		},
		secondary: {
			main: '#8B6137',
		},
		type: 'light',
		background: {
			paper: 'white',
		},
	},
	overrides: {
		MuiTooltip: {
			tooltip: {
				backgroundColor: '#2c3e50',
				boxShadow: '0 2px 4px rgba(0,0,0,.4)',
				border: '.5px solid rgba(255,255,255,.3)'
			},
		},
		MuiPickersToolbar: {
			toolbar: {
				backgroundColor: '#303f9f',
			}
		},
		MuiButton: {
			root: {
				background: 'linear-gradient(#E2B985, #8B6137)',
				"&:hover": {
					backgroundColor: '#E2B985',
				}
			},
			label: {
				...SemiBold,
				color: 'white',
			}
		}
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 850,
			lg: 1280,
			xl: 1920,
		}
	}
}
